import {
  AmpersandLinkedInExtensionRequest,
  AmpersandLinkedInExtensionRequestType,
  AmpersandLinkedInExtensionRespond,
  linkedinExtensionConverter,
} from "@sequoiacap/shared/models";
import { asyncCallWithTimeout } from "@sequoiacap/shared/utils/asyncCallWithTimeout";
import { useAuth } from "~/components/auth/useAuth";
import { useReadDocument } from "./firebase/FirebaseAPI";
import getConfig from "next/config";
import useSWR from "swr";

const { publicRuntimeConfig } = getConfig();
const { LINKEDIN_EXTENSION_ID } = publicRuntimeConfig;

export type ErrorName =
  | "NO_EXTENSION_INSTALLED_ERROR"
  | "CHROME_RUNTIME_ERROR"
  | "REQUEST_ERROR"
  | "MISTYPE_ERROR"
  | "Error";

export class SendLinkedInExtensionMessageError extends Error {
  type: ErrorName;
  requestType: string;
  constructor(
    type: ErrorName,
    requestType: string,
    message: string | undefined,
  ) {
    super(message);
    this.requestType = requestType;
    this.type = type;
  }
}

export function sendLinkedInExtensionMessage(
  request: AmpersandLinkedInExtensionRequest,
  timeout: number = 5000,
): Promise<AmpersandLinkedInExtensionRespond> {
  const promise = new Promise<AmpersandLinkedInExtensionRespond>(
    (resolve, reject) => {
      if (
        typeof chrome === "undefined" ||
        typeof chrome.runtime === "undefined"
      ) {
        reject(
          new SendLinkedInExtensionMessageError(
            "NO_EXTENSION_INSTALLED_ERROR",
            request.type,
            "No extension installed",
          ),
        );
        return;
      }

      chrome.runtime.sendMessage(LINKEDIN_EXTENSION_ID, request, (response) => {
        if (chrome.runtime.lastError) {
          reject(
            new SendLinkedInExtensionMessageError(
              "CHROME_RUNTIME_ERROR",
              request.type,
              chrome.runtime.lastError.message,
            ),
          );
          return;
        }
        if (response.error) {
          reject(
            new SendLinkedInExtensionMessageError(
              "REQUEST_ERROR",
              request.type,
              response.error,
            ),
          );
          return;
        }
        if (response?.type !== request.type) {
          reject(
            new SendLinkedInExtensionMessageError(
              "MISTYPE_ERROR",
              request.type,
              `Expected response type ${request.type}, but got ${response?.type}`,
            ),
          );
          return;
        }
        resolve(response);
      });
    },
  );
  if (!timeout) {
    return promise;
  }
  return asyncCallWithTimeout(promise, 5000);
}

export function useAPIListenLinkedinExtension() {
  const { authUser } = useAuth();

  return useReadDocument(
    authUser?.id ? `/linkedin_extension/${authUser.id}` : null,
    linkedinExtensionConverter,
    { listen: true },
  );
}

export function useAPILinkedInExtensionAuthStatus(doRequest = true) {
  const { data, error, isLoading } = useSWR(
    doRequest ? "linkedin-extension-auth-status" : null,
    async () => {
      let response;
      let requestError;
      try {
        response = await sendLinkedInExtensionMessage({
          type: AmpersandLinkedInExtensionRequestType.AuthStatus,
        });
      } catch (err) {
        if (err instanceof SendLinkedInExtensionMessageError) {
          requestError = err;
        } else {
          throw err;
        }
      }

      const isExtensionDefinitelyDisconnected =
        requestError?.type === "CHROME_RUNTIME_ERROR";
      const isExtensionInstalled =
        !requestError?.type || isExtensionDefinitelyDisconnected;
      const isExtensionConnected =
        isExtensionInstalled && !isExtensionDefinitelyDisconnected;
      const result = {
        type: AmpersandLinkedInExtensionRequestType.AuthStatus,
        signedIn: false,
        ...(response ?? {}),
        isExtensionConnected,
        isExtensionInstalled,
      };
      return result;
    },
    {
      refreshWhenOffline: true, // asking the extension doesnt require internet
    },
  );

  return {
    loading: doRequest && isLoading,
    data,
    error,
  };
}
