import {
  Attachment,
  Comment,
  ParentEntity,
  Post,
  ServerTimestamp,
  commentConverter,
  convertAttachmentToFirebaseModel,
  postConverter,
} from "@sequoiacap/shared/models";
import {
  CloudFunctionName,
  FIREBASE_WRITE_TIMEOUT,
  ReadDocumentOptions,
  callCloudFunction,
  useReadDocument,
  useReadQuery,
} from "./firebase/FirebaseAPI";
import {
  PaginatedResult,
  ReadDocumentReturnType,
  ReadQueryReturnType,
} from "./firebase/types";
import {
  Timestamp,
  deleteField,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  TrackErrorEvent,
  TrackEvent,
  track,
  trackError,
} from "~/utils/analytics";
import { WhereType, fuego, mutateDocument } from "./swr-firebase";
import { asyncCallWithTimeout } from "@sequoiacap/shared/utils/asyncCallWithTimeout";
import { omitUndefined } from "@sequoiacap/shared/utils/omitUndefined";
import { uniqBy } from "lodash";
import {
  useSearchGetCommentReplies,
  useSearchGetLatestComments,
  useSearchGetPostComments,
} from "./search-api";
import getFirebaseDataConverter from "./firebase/firebase-data-converter";

export function useAPIGetComment(
  postId: string | null,
  commentId: string | null,
  options: ReadDocumentOptions<Comment> = {},
): ReadDocumentReturnType<Comment> {
  return useReadDocument(
    postId && commentId ? `post/${postId}/comment/${commentId}` : null,
    commentConverter,
    options,
  );
}

export function useAPIGetLatestComments(
  postId: string | null,
  limit = 2,
): ReadQueryReturnType<Comment> {
  const {
    data: comments,
    loading,
    error,
  } = useSearchGetLatestComments(postId ?? undefined, limit);
  return {
    data: comments?.map((c) => c.data),
    loading: !!loading,
    error,
  };
  // return useReadQuery(
  //   postId ? `post/${postId}/comment` : null,
  //   commentConverter,
  //   limit,
  //   ["created_at", "desc"],
  //   [],
  // );
}

export function useAPIGetPostComments(
  postId: string | null,
  limit = 4,
): PaginatedResult<Comment> {
  const {
    data: comments,
    loading,
    hasMore,
    error,
    nextPage,
  } = useSearchGetPostComments(postId ?? undefined, limit);
  const resultFromAlgolia = {
    data: comments?.map((c) => c.data),
    loading: !!loading,
    hasMore,
    nextPage,
    error,
  };

  const lastestCreatedAt = resultFromAlgolia.data?.[0]?.createdAt;
  const shouldFetchFromFirestore =
    !!lastestCreatedAt ||
    (postId && !resultFromAlgolia.loading && !resultFromAlgolia.data?.length);

  const where: WhereType = lastestCreatedAt
    ? [
        ["parent_id", "==", `post:${postId}`],
        [
          "created_at",
          ">",
          Timestamp.fromMillis(lastestCreatedAt?.getTime() ?? 0),
        ],
      ]
    : [["parent_id", "==", `post:${postId}`]];

  const resultFromFirestore = useReadQuery(
    shouldFetchFromFirestore ? `post/${postId}/comment` : null,
    commentConverter,
    limit,
    ["created_at", "desc"],
    where,
  );

  if (resultFromFirestore.loading || resultFromFirestore.isValidating) {
    return {
      ...resultFromAlgolia,
      loading: true,
    };
  }

  if (!resultFromFirestore.data?.length) {
    return resultFromAlgolia;
  }

  console.log(
    `useAPIGetPostComments/postId=${postId} merging ${resultFromFirestore.data.length} new comments from firestore`,
  );

  const data = resultFromAlgolia.data ?? [];
  data.unshift(...resultFromFirestore.data);
  return {
    ...resultFromAlgolia,
    data: uniqBy(data, "id"),
  };
}

export function useAPIGetCommentReplies(
  postId: string | null,
  commentId: string | null,
): ReadQueryReturnType<Comment> {
  const {
    data: comments,
    loading,
    error,
  } = useSearchGetCommentReplies(commentId ?? undefined);
  const resultFromAlgolia = {
    data: comments
      ?.map((c) => c.data)
      ?.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime()),
    loading: !!loading,
    error,
  };

  const lastestCreatedAt =
    resultFromAlgolia.data?.[resultFromAlgolia.data?.length - 1]?.createdAt;
  const shouldFetchFromFirestore =
    !!lastestCreatedAt ||
    (postId &&
      commentId &&
      !resultFromAlgolia.loading &&
      !resultFromAlgolia.data?.length);
  const where: WhereType = lastestCreatedAt
    ? [
        ["parent_id", "==", `comment:${commentId}`],
        [
          "created_at",
          ">",
          Timestamp.fromMillis(lastestCreatedAt?.getTime() ?? 0),
        ],
      ]
    : [["parent_id", "==", `comment:${commentId}`]];

  const resultFromFirestore = useReadQuery(
    shouldFetchFromFirestore ? `post/${postId}/comment` : null,
    commentConverter,
    undefined,
    ["created_at", "desc"],
    where,
  );

  if (resultFromFirestore.loading || resultFromFirestore.isValidating) {
    return {
      ...resultFromAlgolia,
      loading: true,
    };
  }

  if (!resultFromFirestore.data?.length) {
    return resultFromAlgolia;
  }

  console.log(
    `useAPIGetCommentReplies/postId=${postId} commentId=${commentId} merging ${resultFromFirestore.data.length} new comments from firestore`,
  );

  const data = resultFromAlgolia.data ?? [];
  data.unshift(...resultFromFirestore.data);
  return {
    ...resultFromAlgolia,
    data: uniqBy(data, "id").sort(
      (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
    ),
  };
}

export async function toggleCommentReaction(
  post: Post,
  comment: Comment,
  reactionType: string,
  loggedInUserId: string,
): Promise<boolean> {
  console.log(
    `toggleReaction postId=${post.id} commentId=${comment.id} reactionType=${reactionType} loggedInUserId=${loggedInUserId}`,
  );

  let map: Record<string, Date> | null = null;
  switch (reactionType) {
    case "like": {
      if (!comment.like) comment.like = {};
      map = comment.like;
      break;
    }
    case "helpful": {
      if (!comment.helpful) comment.helpful = {};
      map = comment.helpful;
      break;
    }
    case "empathize": {
      if (!comment.empathize) comment.empathize = {};
      map = comment.empathize;
      break;
    }
  }
  if (map) {
    if (map[loggedInUserId]) {
      delete map[loggedInUserId];
      await deleteCommentReaction(
        post.id,
        comment.id,
        reactionType,
        loggedInUserId,
      );
    } else {
      map[loggedInUserId] = new Date();
      await addCommentReaction(
        post.id,
        comment.id,
        reactionType,
        loggedInUserId,
      );
    }
  }
  return true;
}

async function deleteCommentReaction(
  postId: string,
  commentId: string,
  reactionType: string,
  loggedInUserId: string,
): Promise<void> {
  const path = `post/${postId}/comment/${commentId}`;
  try {
    await updateDoc(doc(fuego.db, path), {
      [`${reactionType}.${loggedInUserId}`]: deleteField(),
      updated_at: serverTimestamp(),
    });
  } catch (err) {
    console.error("deleteCommentReaction/error", err);
  }
  await mutateDocument(path, commentConverter, true);
}

async function addCommentReaction(
  postId: string,
  commentId: string,
  reactionType: string,
  loggedInUserId: string,
): Promise<void> {
  const path = `post/${postId}/comment/${commentId}`;
  try {
    await setDoc(
      doc(fuego.db, path),
      {
        [reactionType]: {
          [loggedInUserId]: serverTimestamp(),
        },
        updated_at: serverTimestamp(),
      },
      {
        merge: true,
      },
    );
  } catch (err) {
    console.error("addCommentReaction/error", err);
  }
  await mutateDocument(path, commentConverter, true);
}

export type PendingComment = {
  postId: string;
  parentId: ParentEntity;
  body?: string;
  createdById: string;
  hashtag?: string[];
  mention?: Record<string, { id: string; name: string }>;
  attachment?: Record<string, Attachment>;
  attributes?: Record<string, string>;
};

export async function createComment(
  draftId: string,
  comment: PendingComment,
  loggedInUserId: string,
): Promise<Comment> {
  const path = `post/${comment.postId}/comment/${draftId}`;
  const newComment = new Comment(
    draftId,
    comment.postId,
    comment.parentId,
    comment.body ?? "",
    ServerTimestamp.create(),
    loggedInUserId,
    ServerTimestamp.create(),
    undefined,
    undefined,
    undefined,
    undefined,
    comment.hashtag,
    comment.mention,
    comment.attachment,
  );
  try {
    await asyncCallWithTimeout(
      setDoc(
        doc(fuego.db, path).withConverter(
          getFirebaseDataConverter(commentConverter),
        ),
        newComment,
      ),
      FIREBASE_WRITE_TIMEOUT,
    );
    track(TrackEvent.commentCreated, {
      comment_id: draftId,
      post_id: comment.postId,
      mention: comment.mention ? Object.keys(comment.mention) : undefined,
      hashtag: comment.hashtag ? comment.hashtag : undefined,
      attachment: comment.attachment ? comment.attachment.length : undefined,
      attributes: comment.attributes,
    });
  } catch (err) {
    console.error("createComment/error", err);
    trackError(TrackErrorEvent.writeComment, err, {
      comment_id: draftId,
      post_id: comment.postId,
      error: `${err}`,
    });
    throw err;
  }
  await mutateDocument(`post/${comment.postId}`, postConverter, true);
  const updated = await mutateDocument(path, commentConverter, true);
  if (!updated) {
    throw new Error("Error saving comment");
  }
  return updated;
}

export async function updateComment(
  currentComment: Comment,
  updateData: PendingComment,
): Promise<Comment> {
  const path = `post/${currentComment.postId}/comment/${currentComment.id}`;
  const updateValue = {
    body: updateData.body ?? currentComment.body,
    hashtag: updateData.hashtag,
    mention: omitUndefined(updateData.mention),
    attachment: convertAttachmentToFirebaseModel(updateData.attachment),
    updated_at: serverTimestamp(),
  };
  try {
    await asyncCallWithTimeout(
      updateDoc(doc(fuego.db, path), updateValue),
      FIREBASE_WRITE_TIMEOUT,
    );
    track(TrackEvent.commentUpdated, {
      comment_id: currentComment.id,
      post_id: currentComment.postId,
      mention: currentComment.mention
        ? Object.keys(currentComment.mention)
        : undefined,
      hashtag: currentComment.hashtag ? currentComment.hashtag : undefined,
      attachment: currentComment.attachment
        ? currentComment.attachment.length
        : undefined,
    });
  } catch (err) {
    console.error("updateComment/error", err);
    trackError(TrackErrorEvent.writeComment, err, {
      comment_id: currentComment.id,
      post_id: currentComment.postId,
      error: `${err}`,
    });
    throw err;
  }

  const updated = await mutateDocument(path, commentConverter, true);
  if (!updated) {
    throw new Error("Error saving comment");
  }
  return updated;
}

export async function deleteComment(comment: Comment): Promise<void> {
  await callCloudFunction(CloudFunctionName.deleteComment, {
    post_id: comment.postId,
    id: comment.id,
  });
  track(TrackEvent.commentDeleted, {
    postId: comment.postId,
    commentId: comment.id,
  });

  const path = `post/${comment.postId}/comment/${comment.id}`;
  await mutateDocument(path, commentConverter, true);
  await mutateDocument(`post/${comment.postId}`, postConverter, true);
}
