import {
  ArrowUpSquareIcon,
  BookmarkIcon,
  CalendarIcon,
  ChatIcon,
  CloseIcon,
  HomeIcon,
  InfoSquareIcon,
  LogoutIcon,
  NotificationIcon,
  ProfileIcon,
  SearchIcon,
  User2Icon,
} from "@sequoiacap/client-ui/icons";
import {
  BaseModal,
  Button,
  ConditionalContainer,
  Float,
  Popover,
  Text,
  TopNav,
  TopNavProps,
  useViewport,
} from "@sequoiacap/client-ui";
import { Fragment, useEffect, useState } from "react";
import { HeaderContainerViewModel } from "./useHeaderContainer";
import { getFirstParam } from "@sequoiacap/shared/utils/getFirstParam";
import { getGroupLink } from "../group/group-utils";
import { useHotkeys } from "react-hotkeys-hook";
import { useRouter } from "next/router";
import Burger from "./Burger";
import NextLink from "~/components/link/NextLink";
import ProfileImageView from "../profile/ProfileImageView";
import ProfileMenuActivityList from "./ProfileMenuActivityList";
import classNames from "classnames";
import styles from "./Header.module.scss";
import useHeaderInfo from "./useHeaderInfo";

export type HeaderProps = TopNavProps & {
  className?: string;
  selectedTab?: string;
  hideMenu?: boolean;
};

export default function Header({
  className,
  selectedTab,
  ...props
}: HeaderProps): JSX.Element {
  const vm = useHeaderInfo();
  const router = useRouter();
  const { viewport } = useViewport();
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsProfileMenuOpen(false);
      setIsNavMenuOpen(false);
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  useHotkeys(
    "meta+k",
    async () => {
      if (router.asPath.startsWith("/search")) {
        return;
      }
      await router.push(`/search${vm.prev ? `?prev=${vm.prev}` : ""}`);
    },
    { enableOnFormTags: true, preventDefault: true },
  );

  const topNavClassName = classNames(
    styles.container,
    props.invertColors && styles.invertColors,
    className,
  );
  const isMobile = viewport === "sm" || viewport === "md";

  if (props.hideMenu || vm.loading || !vm.userId) {
    return <TopNav className={topNavClassName} {...props} />;
  }

  return (
    <TopNav
      className={topNavClassName}
      logoContainer={(logo) =>
        isMobile ? (
          <div className={styles.burgerButtonContainer}>
            <Burger
              className={classNames(props.invertColors && styles.invertColors)}
              open={isNavMenuOpen}
              onToggle={setIsNavMenuOpen}
            />
          </div>
        ) : (
          <NextLink href="/" className={styles.logo}>
            {logo}
          </NextLink>
        )
      }
      contentClassName={styles.content}
      {...props}
    >
      {!vm.loading && (
        <>
          <ConditionalContainer
            condition={isMobile}
            container={(children) => (
              <BaseModal
                position="left"
                onClose={() => setIsNavMenuOpen(false)}
                open={isNavMenuOpen}
                className={styles.menuModal}
                noPadding
                hideCloseButton
              >
                <div className={styles.menuModalHeader}>
                  <Button
                    type="button"
                    className={styles.burgerButton}
                    variant="plain"
                    startIcon={<CloseIcon />}
                    onClick={() => setIsNavMenuOpen(false)}
                  />
                  <Text variant="label1">Menu</Text>
                </div>
                {children}
              </BaseModal>
            )}
          >
            <HeaderMainLinks vm={vm} selectedTab={selectedTab} />
          </ConditionalContainer>
          <HeaderIconLinks vm={vm} selectedTab={selectedTab} />
          <ConditionalContainer
            condition={isMobile}
            container={(children) => (
              <>
                <TopNav.Item
                  onClick={() => setIsProfileMenuOpen(true)}
                  selected={selectedTab === "profile"}
                  iconOnly
                >
                  <ProfileImageView
                    userId={vm.userId}
                    notification={vm.unreadActivityStr}
                  />
                </TopNav.Item>
                <BaseModal
                  position="right"
                  onClose={() => setIsProfileMenuOpen(false)}
                  open={isProfileMenuOpen}
                  className={styles.menuProfileModal}
                  noPadding
                  hideCloseButton
                >
                  {children}
                </BaseModal>
              </>
            )}
            fallback={(children) => (
              <Popover as={Fragment}>
                <Float placement="bottom-end">
                  <TopNav.Item
                    as={Popover.Button}
                    selected={selectedTab === "profile"}
                    iconOnly
                  >
                    <ProfileImageView
                      userId={vm.userId}
                      notification={vm.unreadActivityStr}
                    />
                  </TopNav.Item>
                  <Popover.Panel className={styles.menuItems}>
                    {children}
                  </Popover.Panel>
                </Float>
              </Popover>
            )}
          >
            <ProfileContent vm={vm} />
          </ConditionalContainer>
        </>
      )}
    </TopNav>
  );
}

const HeaderMainLinks = ({
  vm,
  selectedTab,
}: {
  vm: HeaderContainerViewModel;
  selectedTab?: string;
}) => {
  return (
    <>
      {[
        vm.isWritableUser && "latest",
        vm.showArcMenu && vm.defaultArcId && "arc",
        vm.isWritableUser && "events",
        "network",
        "resources",
      ].map((item) =>
        item ? (
          <TopNav.Item
            key={item}
            as={NextLink}
            className={styles.navItem}
            href={hrefFromHamburgerItem(item)}
            selected={isSelectedFromHamburgerItem(item, selectedTab)}
          >
            {displayFromHamburgerItem(item)}
          </TopNav.Item>
        ) : null,
      )}
    </>
  );
};

const HeaderIconLinks = ({
  vm,
  selectedTab,
}: {
  vm: HeaderContainerViewModel;
  selectedTab?: string;
}) => {
  const prev = vm.prev;
  const query = getFirstParam(useRouter().query.query);
  const searchParams: Record<string, string | undefined> = { prev, query };
  const searchParamsStr = Object.keys(searchParams)
    .filter((k) => !!searchParams[k])
    .map(
      (k) =>
        `${encodeURIComponent(k)}=${encodeURIComponent(searchParams[k] ?? "")}`,
    )
    .join("&");

  return (
    <>
      <TopNav.Item
        as={NextLink}
        href={`/search${searchParamsStr ? `?${searchParamsStr}` : ""}`}
        selected={selectedTab === "search"}
        iconOnly
      >
        <SearchIcon />
      </TopNav.Item>
      {vm.isWritableUser && (
        <TopNav.Item
          as={NextLink}
          href="/chat"
          selected={selectedTab === "chat"}
          iconOnly
        >
          <ChatIcon />
          {vm.unreadMessageStr && (
            <Text className={styles.notificationDot} variant="body3Sans">
              {vm.unreadMessageStr}
            </Text>
          )}
        </TopNav.Item>
      )}
    </>
  );
};

const ProfileContent = ({ vm }: { vm: HeaderContainerViewModel }) => {
  return (
    <div className={styles.profileContent}>
      <div className={styles.profileContentHeader}>
        <ProfileImageView userId={vm.userId} size="sm" />
        <Text variant="header4">{vm.displayName}</Text>
      </div>
      {vm.isWritableUser && (
        <div className={styles.profileContentNotificationsSection}>
          <Text
            className={styles.profileContentNotificationsSectionHeader}
            variant="body2Sans"
          >
            <NotificationIcon width={16} height={16} />
            Activity
          </Text>
          <ProfileMenuActivityList />
        </div>
      )}
      {!!vm.defaultBaseCampId && (
        <div className={styles.profileContentSection}>
          <Text
            variant="label1"
            className={styles.profileContentSectionHeader}
            static
          >
            Base Camp
          </Text>
          <Text
            as={NextLink}
            href="/base-camp"
            className={styles.profileContentSectionLink}
            variant="body2Sans"
          >
            <HomeIcon width={16} height={16} />
            Hub
          </Text>
          <Text
            as={NextLink}
            href={getGroupLink(`base-camp-${vm.defaultBaseCampId}`)}
            className={styles.profileContentSectionLink}
            variant="body2Sans"
          >
            <User2Icon width={16} height={16} />
            Group
          </Text>
        </div>
      )}
      {!!vm.showArcMenu && (
        <div className={styles.profileContentSection}>
          <Text
            variant="label1"
            className={styles.profileContentSectionHeader}
            static
          >
            Arc
          </Text>
          {!!vm.defaultArcId && (
            <>
              <Text
                as={NextLink}
                href="/arc"
                className={styles.profileContentSectionLink}
                variant="body2Sans"
              >
                <HomeIcon width={16} height={16} />
                Hub
              </Text>
              <Text
                as={NextLink}
                href={getGroupLink(`arc-${vm.defaultArcId}`)}
                className={styles.profileContentSectionLink}
                variant="body2Sans"
              >
                <User2Icon width={16} height={16} />
                Group
              </Text>
            </>
          )}
          <Text
            as={NextLink}
            href="/event?tab=officeHours"
            className={styles.profileContentSectionLink}
            variant="body2Sans"
          >
            <CalendarIcon width={16} height={16} />
            Arc Office Hours
          </Text>
        </div>
      )}
      {!!vm.showScoutMenu && (
        <div className={styles.profileContentSection}>
          <Text
            variant="label1"
            className={styles.profileContentSectionHeader}
            static
          >
            Scout
          </Text>
          <Text
            as={NextLink}
            href="/submit-opportunity"
            className={styles.profileContentSectionLink}
            variant="body2Sans"
          >
            <ArrowUpSquareIcon width={16} height={16} />
            Create a Memo
          </Text>
          {!!vm.defaultScoutCampId && (
            <Text
              as={NextLink}
              href="/scout-camp"
              className={styles.profileContentSectionLink}
              variant="body2Sans"
            >
              <HomeIcon width={16} height={16} />
              Scouts Connect
            </Text>
          )}
        </div>
      )}
      {vm.isWritableUser && (
        <div className={styles.profileContentSection}>
          <Text
            as={NextLink}
            href="/profile"
            className={styles.profileContentSectionLink}
            variant="body2Sans"
          >
            <ProfileIcon width={16} height={16} />
            Profile
          </Text>
          <Text
            as={NextLink}
            href="/bookmarks"
            className={styles.profileContentSectionLink}
            variant="body2Sans"
          >
            <BookmarkIcon width={16} height={16} />
            My Bookmarks
          </Text>
          <Text
            as={NextLink}
            href="/support"
            className={styles.profileContentSectionLink}
            variant="body2Sans"
          >
            <InfoSquareIcon width={16} height={16} />
            Ampersand Help
          </Text>
        </div>
      )}
      <div className={styles.profileContentSection}>
        <Text
          as="button"
          className={styles.profileContentSectionLink}
          onClick={() => vm.signOut()}
          variant="body2Sans"
        >
          <LogoutIcon width={16} height={16} />
          Log out
        </Text>
      </div>
    </div>
  );
};

const hrefFromHamburgerItem = (item: string) => {
  // Most of the tab names are the url, but events has to go to /event.
  if (item === "events") return "/event";
  return `/${item}`;
};

const displayFromHamburgerItem = (item: string) => {
  return item.replace(/-/g, " ");
};

const isSelectedFromHamburgerItem = (
  item: string,
  selectedTab: string | undefined,
) => {
  if (item === selectedTab) {
    return true;
  }

  if (selectedTab === "resources" && item === "library") {
    return true;
  }
  if (selectedTab === "library" && item === "resources") {
    return true;
  }
  return false;
};
