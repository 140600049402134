import {
  Byline,
  type BylineProps,
  List,
  type ListItemProps,
  useOnScreen,
} from "@sequoiacap/client-ui";
import { Notification } from "@sequoiacap/shared/models";
import { markNotificationsAsRead } from "~/network/notification-api";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import ActivityText from "./ActivityText";
import NextLink from "~/components/link/NextLink";
import ProfileImageView from "~/components/profile/ProfileImageView";
import classNames from "classnames";
import styles from "./ActivityCell.module.scss";
import useActivityCell from "./useActivityCell";

/** Used by others to manage the state of this list */
export type ActivityCellState = {
  hidden: boolean;

  /** Individual activity is loaded on mount but the outer ActivityContainer needs to know about it. Store the value here */
  loading: boolean;

  /**
   * Individual activity is loaded on mount but the outer ActivityContainer needs to know whether the activity has content. Store the value here.
   *
   * During loading, this is falsy
   */
  hasContent?: boolean;
};

export type ActivityProps = ListItemProps & {
  notification: Notification;
  didLoad?: (hasContent: boolean) => void;
  onNotificationClick?: (notification: Notification) => void;
  bylineProps?: BylineProps;
};

export function ActivityCell({
  className,
  notification,
  onNotificationClick,
  didLoad,
  bylineProps,
  ...props
}: ActivityProps): JSX.Element | null {
  const router = useRouter();
  const vm = useActivityCell(notification);
  const { error, loadedMetadata, hasBeenRead, dateTimeString, link } = vm;
  const { sender } = loadedMetadata;
  const [cellElement, setCellElement] = useState<HTMLElement | null>(null);
  const isOnScreen = useOnScreen(cellElement);
  const emptyContent = error || !sender;

  useEffect(() => {
    if (isOnScreen) {
      markNotificationsAsRead([notification]).catch(console.error);
    }
  }, [isOnScreen, notification]);

  useEffect(() => {
    if (vm.loading) return;
    didLoad?.(!emptyContent);
  }, [vm.loading, didLoad, emptyContent]);

  // There is currently no data to back this stuff so I'm just putting
  // in some very minor dummy stuff. So the profile image will be populated.
  if (emptyContent) {
    return null;
  }

  return (
    <List.Item
      className={classNames(styles.activityCell, className)}
      onClick={async () => {
        if (link && router.asPath !== link) {
          // Link doesn't always have prefix slash
          const linkWithSlash = link.startsWith("/") ? link : `/${link}`;
          await router.push(linkWithSlash);
          if (onNotificationClick) onNotificationClick(notification);
        }
      }}
      ref={setCellElement}
      {...props}
    >
      <Byline
        avatar={
          <NextLink
            href={`/profile?user=${sender.id}`}
            className={styles.profileLinkStyle}
          >
            <ProfileImageView userId={sender.id} />
          </NextLink>
        }
        header={<ActivityText notification={notification} vm={vm} />}
        description={dateTimeString}
        spacing="lg"
        as="div"
        {...bylineProps}
      />
      {!hasBeenRead && <div className={styles.unreadIndicator} />}
    </List.Item>
  );
}
