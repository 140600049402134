import { useEffect } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

type InfiniteScrollProps = {
  className?: string;
  onLoadMore: () => void;
  hasNextPage: boolean;
  loading: boolean;
  loader: React.ReactNode;
  children?: React.ReactNode;
  endMessage?: React.ReactNode;
  disabled?: boolean;
  initialLoad?: boolean;
  hideContentWhileLoading?: boolean;
  validating?: boolean;
};

export const InfiniteScroll = ({
  className,
  onLoadMore,
  hasNextPage,
  loader,
  loading,
  children,
  endMessage = null,
  disabled = false,
  initialLoad = false,
  hideContentWhileLoading = false,
  validating = false,
}: InfiniteScrollProps) => {
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    rootMargin: "0px 0px 100px 0px",
    disabled,
  });

  // Hack to manually trigger onLoadMore. For some reason `useGetComments` keeps sending back
  // hasNextPage=true even when there are no more comments to load.
  useEffect(() => {
    if (initialLoad && !validating) {
      setTimeout(onLoadMore);
    }
  }, [validating, initialLoad, onLoadMore]);

  return (
    <div className={className}>
      {hideContentWhileLoading && loading ? null : children}
      {(loading || hasNextPage) && (
        <div ref={sentryRef} data-infinite-scroll-loader>
          {loader}
        </div>
      )}
      {!hasNextPage && endMessage}
    </div>
  );
};
